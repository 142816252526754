
import numeral from 'numeral'


const INCH = 0.03937
const POUND = 0.00220462

export default function Value({ 
	type, 
	value,
	inK = false, 
}) {

	switch (type) {

		case 'mm':
			return `${numeral(value/10).format('0[.]0')} cm`
		case 'in':
			return `${numeral(value*INCH).format('0[.]0')}"`

		case 'g':
			return `${numeral(value/1000).format('0[.]0')} kg`
		case 'lbs':
			return `${numeral(value*POUND).format('0[.]0')} lbs`

		case '$':
			return `$${numeral(value).format(inK ? '0.0a' : '0,0')}`
			// return `$${numeral(value).format('0.0a')}`

		default:
			return `${value} ${type}`
	}
	
}