
module.exports = {

	'LinusTechTips': {
		name: 'Linus Tech Tips',
		url: 'https://www.youtube.com/channel/UCXuqSBlHAE6Xw-yeJA0Tunw',
	},

	'ShortCircuit': {
		name: 'ShortCircuit',
		url: 'https://www.youtube.com/channel/UCdBK94H6oZT2Q7l0-b0xmMg',
	},

	'RTINGS': {
		name: "RTINGS",
		url: 'https://www.rtings.com',
	},
	
	'DisplayNinja': {
		name: "Display Ninja",
		url: 'https://www.displayninja.com',
	},

	'HardwareUnboxed': {
		name: "Hardware Unboxed",
		url: 'https://www.youtube.com/channel/UCI8iQa1hv7oV_Z8D35vVuSg',
	},

	'tftcentral': {
		name: "TFT Central",
		url: 'https://www.tftcentral.co.uk/',
	},

	'tomshardware': {
		name: "Tom's Hardware",
		url: 'https://www.tomshardware.com',
	},
	
}
