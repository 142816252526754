
import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useRecoilValue } from 'recoil'
import cn from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

import Port from '../components/Port'
import PortSymbol from '../helpers/PortSymbol'
import Value from '../components/Value'
import useBookmarks from '../components/useBookmarks'
import HDR from '../components/HDR'
import GSYNC from '../components/GSYNC'
import FreeSync from '../components/FreeSync'
import panelTypes from '../config/panelTypes'
import { usePopover } from '../helpers/Popover'
import Meta from '../helpers/Meta'
import Symbol from '../helpers/Symbol'
import formatValue from '../helpers/formatValue'
import Price from '../helpers/Price'
import SORTATTRS from '../config/monitorAttributes'
import { filteredProductsAtom, sortAttrAtom } from '../components/atoms'
import STORES from '../../../config/stores'
import REVIEWERS from '../../../config/reviewers'

import '../styles/Monitor.sass'

const COLORCOVERAGE = [
	'sRGB',
	'AdobeRGB',
	'NTSC',
	'DCIP3',
]

function Popover({ url, children }) {
	const { popoverHandlers } = usePopover({ url })
	return <span
		className='po'
		{...popoverHandlers}
	>
		{ children }
	</span>
}

// function getAspectClass(image) {
// 	const num = image.file.childImageSharp.fluid.aspectRatio > 2
// 		? '2'
// 		: image.file.childImageSharp.fluid.aspectRatio > 1
// 		? '1'
// 		: '0'
// 	return `mpgR${num}`
// }

// function ucfirst(string) {
// 	return `${string.substr(0, 1).toUpperCase()}${string.substr(1)}`
// }

export default function Monitor({ data: { item } }) {

	const m = item

	const { isBookmarked, BookmarkButton } = useBookmarks(m.slug)
	const [imageIdx, setImageIdx] = React.useState(null)

	const name = `${m.brand.name} ${m.model}`
	const resolutionName = (!!m.resolutionStandard && !!m.resolutionStandard[0])
		? `${m.resolutionStandard[0].attrs.dual ? "Dual " : ""}${ m.resolutionStandard[0].name }`
		: null

	return <>

		<Meta 
			title={`${name} ${m.screenSize}" ${panelTypes[m.panelTech].label} ${resolutionName ?`${resolutionName} ` : ''} monitor`}
			url={`/monitors/${m.slug}`}
		/>
		
		<main id='MonitorPage' className='Page'>

			<ProductsNavbar currentSlug={m.slug} />

			<div id='mpgMainImage'>

				{ (imageIdx !== null)
					?	<GatsbyImage 
							// fluid={m.gallery[imageIdx].file.childImageSharp.fluid} 
							// backgroundColor='#ffffff'
							// style={{ height: '100%', height: '100%' }}
							// imgStyle={{ objectFit: 'contain' }}
							image={getImage(m.gallery[imageIdx].file)}
							alt={name}
						/>
					: <GatsbyImage 
							image={getImage(m.image.file)} 
							alt={name}
							loading='eager' 

						/>
				}
				
			</div>
			
			<div id='mpgThumbs'>
				{ m.thumbs.map((image, idx) =>
					<div key={idx} 
						className={cn(`mpgThumb`, { active: imageIdx === idx })}
						onClick={() => setImageIdx(imageIdx === idx ? null : idx)}
					>
						<GatsbyImage
							key={idx}
							image={getImage(image.file)}
							objectFit='contain'
							// imgStyle={{ objectFit: 'contain' }}
							// fluid={image.file.childImageSharp.fluid} 
							alt={image.title || name} 
							className={`mpgThumbImage`}
						/>
					</div>
				)}
			</div>

			<header>
				<h1 id='mpdTitle' className={isBookmarked ? 'pinned' : ''}>
					{name}
					{ BookmarkButton }
				</h1>
				{ !!m.alias &&
					<p>{ m.alias.split(/\n\r?/).join(' / ') }</p> }
			</header>

			<section id='mpSpecs'>
					
				<h2>Specs</h2>

				<dl id='mpdlSpecs'>

					<dt>Panel:</dt>
					<dd>
						<Popover url={m.panelTech}>{panelTypes[m.panelTech].label}</Popover>
						{ !!m.curveRadius && " curved" }
						{/* <br /> */}
						{/* { !!m.backlightSource && <>
							{ `${BACKLIGHTSOURCES[m.backlightSource]} backlight` }
							<br />
						</>} */}
					</dd>

					{ !!m.curveRadius && <>
						<dt>Curve radius:</dt>
						<dd>{m.curveRadius}mm</dd>
					</>}

					<dt>Screen size:</dt>
					<dd>{formatValue(m.screenSize, '"')}</dd>

					<dt>Resolution:</dt>
					<dd>
						{m.resolutionH} x {m.resolutionV} px
						{ !!resolutionName && <>
							<br />
							{resolutionName}
						</>}
					</dd>
					
					<dt>Aspect ratio:</dt>
					<dd>{item.aspectRatio.replace(':', ' : ')}</dd>

					<dt>Pixel density:</dt>
					<dd>{item.pixelDensity} PPI</dd>
	
					<dt>Brightness:</dt>
					<dd>
						{item.brightness} cd/m2
					</dd>

					{ !!m.dimmingZones && <>
						<dt>Dimming zones:</dt>
						<dd>{m.dimmingZones}</dd>
					</>}
					
					{/* { item.brightnessMax && <>
						<dt>Dynamic brightness:</dt>
						<dd>{item.brightnessMax} cd/m2</dd>
					</>} */}

					<dt>Contrast:</dt>
					<dd>{item.contrast}:1</dd>

					{/* { m.dynamicContrast && <>
						<dt>Dynamic contrast:</dt>
						<dd>{item.dynamicContrast} :1</dd>
					</> } */}
				
					<dt>Color depth:</dt>
					<dd>
						{m.colorDepth} bit { !!m.frc && 
						<>+ <Popover url='FRC'>FRC</Popover></> }
					</dd>
				
					{ COLORCOVERAGE.filter(id => !!m[id]).map(id => 
						<React.Fragment key={id}>
							<dt>{id}:</dt>
							<dd>{m[id]}%</dd>
						</React.Fragment>
					)}

					<dt>Refresh rate:</dt>
					<dd>{m.refreshRate} Hz</dd>

					<dt>Response time:</dt>
					<dd>{m.responseTime} ms</dd>

					{ !!m.viewAngleH && <>
						<dt>Viewing angle:</dt>
						<dd>↔ {m.viewAngleH}°  ↕ {m.viewAngleV}°</dd>
					</>}

				</dl>

				{ !!m.panelBrand && <p>
					Panel manufactured by {m.panelBrand.name}.
					{ !!m.panelName && ` Model: ${m.panelName}.`}
				</p>}

				{ !!m.release && <p>
					Released in { dayjs(m.release).format('MMMM YYYY') }.
				</p> }

			</section>


			<section id='mpSyncHDR' className='mpdSyncHDR'>

				<h2>HDR</h2>
				{ m.hdr
					?	<HDR type={m.hdr} />
					: <p>not available</p>
				}
		
				<h2>Adaptive sync</h2>
				{ (m.gsync || m.freesync)
					?	<>
							{ m.gsync && <GSYNC type={m.gsync} /> }
							{ m.freesync && <FreeSync type={m.freesync} /> }		
						</>
					: <p>not available</p>
				}
			</section>


			<section id='mpOffers'>
				<h2>Offers</h2>
				<ul id='mpOffersList'>
					{ m.offers.map((offer, idx) => 
						<li key={idx} className='mpdOffer'>
							<h3 className='mpdoStore'>
								{ offer.store === 'BRAND'
									?	<><strong>{m.brand.name}</strong> official store</>
									:	<img 
											src={`/stores/${offer.store}.png`}
											alt={STORES[offer.store].name}
											className='mpdoImage'
										/>
								}
							</h3>
							<p className='mpdoPrice'>
								<Price value={offer.prices[0].price} />
							</p>
							{ offer.stock 
								? <p className='mpdoInStock'>in stock</p>
								: <p className='mpdoOutOfStock'>out of stock</p>
							}								
							<p>
								<a href={offer.url} className='mpdoLink'>
									Go to store <Symbol id='external' />
								</a>
							</p>
							<p className='mpdoCheck'>
								<small>last checked:</small><br />
								{ dayjs(offer.prices[0].date).fromNow() }
							</p>
						</li>
					)}
				</ul>
			</section>


			<section id='mpPorts'>
				<h2>Ports</h2>
				<ul id='mpUlPorts'>
					{ m.ports.map((port, idx) => 
						<li key={idx} className='mpdpPort'>
							<div className='mpdpSymbols'>
								{ [...Array(port.count ?? 1).keys()].map(i =>
									<PortSymbol id={port.slug} key={i} />
								)}
							</div>
							<Port port={port} />
						</li>
					)}
				</ul>
			</section>



			<section>
				<h2>Features</h2>
				<ul>
					{ !!m.vesa && <li>
						<Popover url='Vesa-mount'>Vesa mount</Popover>: {m.vesa.join(' / ')}
					</li> }
					{ m.lightSensor && 
						<li>ambient light sensor</li> }
					{ !!m.hdcp && 
						<li><Popover url='HDCP'>HDCP 2.2</Popover></li> }
					{ m.backlight === 'rgb' && 
						<li>RGB backlight</li> }
					{ !!m.pbp && 
						<li><em>Picture by Picture</em> support</li> }
					{ !!m.kvm && 
						<li>KVM switch</li> }
					{ !!m.lock &&
						<li>Kensington lock</li> }
					{ !!m.speakers && <li>
						{ !!m.speakersWatt
							&& `${m.speakersWatt}W ` }
						{ m.speakers === 'stereo'
							? "stereo speakers"
							: "mono speaker" }
					</li> }
				</ul>
			</section>

		

			<section>
				<h2>Dimensions</h2>
				<table id='mpDimensions'>
					<thead>
						<tr>
							<th></th>
							<th>Screen</th>
							<th>with stand</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th className='n'>Width</th>
							<td className='so'>
								<Value type='mm' value={m.width} /> / <Value type='in' value={m.width} />
							</td>
							<td className='ws'>
								<Value type='mm' value={m.width} /> / <Value type='in' value={m.width} />
							</td>
						</tr>
						<tr>
							<th className='n'>Height</th>
							<td className='so'>
								<Value type='mm' value={m.height} /> / <Value type='in' value={m.height} />
							</td>
							<td className='ws'>
								<Value type='mm' value={m.heightStand} /> / <Value type='in' value={m.heightStand} />
							</td>
						</tr>
						<tr>
							<th className='n'>Depth</th>
							<td className='so'>
								<Value type='mm' value={m.thick} /> / <Value type='in' value={m.thick} />
							</td>
							<td className='ws'>
								<Value type='mm' value={m.thickStand} /> / <Value type='in' value={m.thickStand} />
							</td>
						</tr>
					</tbody>
				</table>

			</section>


			<section>
				{ !!m.reviews && <>
					<h2>Reviews</h2>
					<Reviews reviews={m.reviews} /> 
				</> }
			</section>


			<section>
				<h2>Weight</h2>
				<dl id='mpdWeight' className='mpdGridH'>
					<dt>Screen:</dt>
					<dd>
						<Value type='g' value={m.weight} /> / <Value type='lbs' value={m.weight} />
					</dd>
					{ !!m.weightStand && <>
						<dt>with stand:</dt>
						<dd>
							<Value type='g' value={m.weightStand} /> / <Value type='lbs' value={m.weightStand} />
						</dd>
					</>}
				</dl>
			</section>


			<section>
				<h2>Stand adjustment</h2>
				<dl id='mpdErgo' className='mpdGridH'>
					<dt>Height:</dt>
					<dd>
						{ !!m.heightAdjustment 
							? <Value type='mm' value={m.heightAdjustment} />
							: "✗"
						}
					</dd>
					<dt>Tilt:</dt>
					<dd>
						{ (!!m.tiltP || !!m.tiltM)
							?	`+${m.tiltP}° / -${m.tiltM}°`
							:	"✗"
						}
					</dd>
					<dt>Swivel:</dt>
					<dd>
						{ !!m.swivel ? `${m.swivel}°` : "✗" }
					</dd>
					<dt>Pivot:</dt>
					<dd>{ !!m.pivot ? `${m.pivot}°` : "✗" }</dd>
				</dl>
			</section>	

			<section>
				<h2>Energy consumption</h2>
				<dl className='mpdGridH'>
					{ !!m.powerMax && <>
						<dt>Maximum:</dt>
						<dd>{m.powerMax}W</dd>
					</> }
					{ !!m.powerAvg && <>
						<dt>Average:</dt>
						<dd>{m.powerAvg}W</dd>
					</>}
					{ !!m.powerSB && <>
						<dt>Stand-by:</dt>
						<dd>{m.powerSB}W</dd>
					</> }
				</dl>
			</section>


			{ m.brandUrl && <section>
				<h2>Links</h2>
				<p>
					<a href={m.brandUrl} className='ext'>
						Manufacturer website<Symbol id='external' />
					</a>
				</p> 
			</section>}

		</main>
		
	</>
}


function ProductsNavbar({ currentSlug }) {

	const searchResults = useRecoilValue(filteredProductsAtom)
	const sortAttr = useRecoilValue(sortAttrAtom)

	const currentIdx = searchResults.findIndex(p => p.slug === currentSlug)

	const maxHeight = searchResults.reduce((acc, curr) => Math.max(curr.height, acc), 0)

	return <nav id='ProductNavbar'>

		<Link to='/monitors/' id='mpnAll' className='mpnLink'>
			<Symbol id='left' /> All results ({ searchResults.length })
		</Link>
		
		<div id='mpnTiles'>
			{ searchResults.map((product, idx) =>
				<Link to={`/monitors/${product.slug}/`}
					key={idx}
					className={ product.slug === currentSlug ? 'active' : ''}
					style={{ 
						flex: product.width,
						height: `${Math.round((product.height/maxHeight)*100)}%`,
					}}
				>
					{ formatValue(product[sortAttr], SORTATTRS[sortAttr].type) }
					{/* { `${product.brand.name} ${product.model}` }  */}
					{/* { `${(product.height/maxHeight)*100}%` } */}
				</Link>
			)}
		</div>

		<Link 
			to={`/monitors/${searchResults[currentIdx === 0 ? searchResults.length-1 : currentIdx-1].slug}/`} 
			className='mpnLink mpnArrow'
		>
			<Symbol id='left' /> Previous
		</Link>

		<Link 
			to={`/monitors/${searchResults[currentIdx === searchResults.length-1 ? 0 : currentIdx+1].slug}/`} 
			className='mpnLink mpnArrow'
		>
			Next <Symbol id='right' />
		</Link>

	</nav>
}



function Reviews({ reviews }) {

	return <ul id='mpReviews'>
		{ reviews.map((review, idx) =>
			<li key={idx}>
				
				<img 
					src={`/reviewers/${review.source}.jpg`}
					alt={REVIEWERS[review.source].name}
				/>

				<h4>
					{ REVIEWERS[review.source].name } <small>- { dayjs(review.date).format('D MMMM YYYY') }</small>

				</h4>

				<a href={review.url} className='ext' target='_blank'>
					{ review.url.replace(/https:\/\/(www\.)?/, '') }
				</a>

				{ review.quote &&
					<blockquote>
						{ review.quote }
					</blockquote>
				}
			</li>
		)}
	</ul>
}


export const pageQuery = graphql`  
	query($slug: String!) {
		item: monitor(slug: { eq: $slug }) {
			slug
			brand { name }
			model
			alias
			price
			offers
			reviews
			resolutionH
			resolutionV
			resolutionStandard
			screenSize
			width
			height
			heightStand
			thick
			thickStand
			weight
			weightStand
			refreshRate
			curveRadius
			dimmingZones
			backlightSource
			responseTime
			aspectRatio
			pixelDensity
			resolutionStandard
			
			brightness
			brightnessMax
			contrast
			dynamicContrast
			colorDepth
			frc
			sRGB
			AdobeRGB
			NTSC
			DCIP3
			hdr
			gsync
			freesync

			lightSensor
			heightAdjust
			swivel
			hdcp
			ports
			release
			asin
			viewAngleV
			viewAngleH
			panelTech
			displayCoat
			tiltM
			tiltP
			pivot
			vesa
			speakers
			speakersWatt
			ports
			powerSB
			powerAvg
			powerMax
			backlight
			kvm
			pbp
			lock
			amazonUrl
			brandUrl
			panelName
			panelBrand { name }
			image {
				file {
					childImageSharp {
						gatsbyImageData(
							width: 1080
							# placeholder: TRACED_SVG
							# tracedSVGOptions: {
							# 	turdSize: 50,
							# 	threshold: 200,
							# }
						)
						# fluid(
						# 	maxWidth: 1024, 
						# 	fit: CONTAIN,
						# 	traceSVG: {
						# 		turdSize: 50,
						# 		threshold: 254,
						# 	}
						# ) {
						# 	...GatsbyImageSharpFluid_withWebp_tracedSVG
						# }
					}
				}
			}
			thumbs: gallery {
				file {
					childImageSharp {
						gatsbyImageData(
							height: 64
							# transformOptions: {
							# 	fit: COVER
							# }
						)
						# fluid(
						# 	maxHeight: 96, 
						# 	fit: CONTAIN,
						# 	traceSVG: {
						# 		turdSize: 50,
						# 		threshold: 250,
						# 	}
						# ) {
						# 	...GatsbyImageSharpFluid_withWebp_tracedSVG
						# }
					}
				}
			}			
			gallery {
				file {
					childImageSharp {
						gatsbyImageData(
							width: 1080,
							# placeholder: TRACED_SVG,
							# tracedSVGOptions: {
							# 	turdSize: 50,
							# 	threshold: 254,
							# }
						)
						# fluid(
						# 	maxWidth: 1024, 
						# 	fit: CONTAIN,
						# 	traceSVG: {
						# 		turdSize: 50,
						# 		threshold: 250,
						# 	}
						# ) {
						# 	...GatsbyImageSharpFluid_withWebp_tracedSVG
						# }
					}
				}
			}			
		}
	}
`
