
module.exports = {

	'BRAND': {
		name: '[brand official store]',
		url: '',
		currency: 'USD',
	},

	'amazon.com': {
		name: 'Amazon',
		url: 'https://amazon.com',
		currency: 'USD',
	},

	'bhphotovideo.com': {
		name: "B&H Photo Video",
		url: 'https://www.bhphotovideo.com/',
		currency: 'USD',
	},

	'microcenter.com': {
		name: "Micro Center",
		url: 'https://www.microcenter.com/',
		currency: 'USD',
	},

	'newegg.com': {
		name: "Newegg",
		url: 'https://newegg.com/',
		currency: 'USD',
	},

	'bestbuy.com': {
		name: "BestBuy",
		url: 'https://bestbuy.com/',
		currency: 'USD',
	},
	
}
